import axios from 'axios';
const addPartner = async (partnerData) => {
  try {
    const response = await axios.post('https://back-end-feizhoucom-delta.vercel.app/partners', partnerData);
    return response.data;
  } catch (error) {
    console.error('Request payload:', partnerData); // Log the request payload
    console.error('Server response:', error.response?.data); // Log the server response
    throw error;
  }
};
const getPartners = async () => {
  try {
    const response = await axios.get('https://back-end-feizhoucom-delta.vercel.app/partners'); 
    return response.data; 
  } catch (error) {
    console.error('Error fetching partners:', error);
    throw error; 
  }
};
const getPartnerById = async (partnerId) => {
  try {
    const response = await axios.get(`https://back-end-feizhoucom-delta.vercel.app/partners/${partnerId}`); 
    return response.data; 
  } catch (error) {
    console.error('Error fetching partner by ID:', error);
    throw error; 
  }
};
const updatePartner = async (partnerId, updatedpartnerData) => {
  try {
    const response = await axios.put(`https://back-end-feizhoucom-delta.vercel.app/partners/${partnerId}`, updatedpartnerData); 
    return response.data; 
  } catch (error) {
    console.error('Error updating partner:', error);
    throw error; 
  }
};
const deletePartner = async (partnerId) => {
  try {
    const response = await axios.delete(`https://back-end-feizhoucom-delta.vercel.app/partners/${partnerId}`); 
    return response.data; 
  } catch (error) {
    console.error('Error deleting partner:', error);
    throw error; 
  }
};

export { addPartner, getPartners, getPartnerById, updatePartner, deletePartner };
