import axios from 'axios';
const addBlog = async (blogData) => {
  try {
    const response = await axios.post('https://back-end-feizhoucom-delta.vercel.app/blogs', blogData);
    return response.data;
  } catch (error) {
    console.error('Request payload:', blogData); // Log the request payload
    console.error('Server response:', error.response?.data); // Log the server response
    throw error;
  }
};
const getBlogs = async () => {
  try {
    const response = await axios.get('https://back-end-feizhoucom-delta.vercel.app/blogs'); 
    return response.data; 
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error; 
  }
};
const getBlogById = async (blogId) => {
  try {
    const response = await axios.get(`https://back-end-feizhoucom-delta.vercel.app/blogs/${blogId}`); 
    return response.data; 
  } catch (error) {
    console.error('Error fetching blog by ID:', error);
    throw error; 
  }
};
const updateBlog = async (blogId, updatedBlogData) => {
  try {
    const response = await axios.put(`https://back-end-feizhoucom-delta.vercel.app/blogs/${blogId}`, updatedBlogData); 
    return response.data; 
  } catch (error) {
    console.error('Error updating blog:', error);
    throw error; 
  }
};
const deleteBlog = async (blogId) => {
  try {
    const response = await axios.delete(`https://back-end-feizhoucom-delta.vercel.app/blogs/${blogId}`); 
    return response.data; 
  } catch (error) {
    console.error('Error deleting blog:', error);
    throw error; 
  }
};

export { addBlog, getBlogs, getBlogById, updateBlog, deleteBlog };
