import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  ADD_PARTNER,
  UPDATE_PARTNER,
  DELETE_PARTNER,
} from '../Types/Partner.js';

const initialState = {
  partners: [],
  loading: false,
  error: null,
};

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: action.payload,
      };
    case FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_PARTNER:
      return {
        ...state,
        PARTNER: [...state.partners, action.payload],
      };
    case UPDATE_PARTNER:
      return {
        ...state,
        partner: state.partners.map(partner =>
          partner._id === action.payload._id
            ? { ...partner, ...action.payload.updatedpartnerFblogData }
            : partner
        ),
      };
    case DELETE_PARTNER:
      return {
        ...state,
        partners: state.partners.filter(partner => partner._id !== action.payload),
      };
    default:
      return state;
  }
};

export default partnerReducer;

