import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  ADD_PARTNER,
  UPDATE_PARTNER,
  DELETE_PARTNER,
} from '../Types/Partner.js';

export const fetchPartnersRequest = () => ({
  type: FETCH_PARTNERS_REQUEST,
});

export const fetchPartnersSuccess = (Partners) => ({
  type: FETCH_PARTNERS_SUCCESS,
  payload: Partners,
});

export const fetchPartnersFailure = (error) => ({
  type: FETCH_PARTNERS_FAILURE,
  payload: error,
});

export const addPartner = (Partner) => ({
  type: ADD_PARTNER,
  payload: Partner,
});

export const updatePartner = (_id, updatedPartnerData) => ({
  type: UPDATE_PARTNER,
  payload: { _id, updatedPartnerData },
});

export const deletePartner = (_id) => ({
  type: DELETE_PARTNER,
  payload: _id,
});
