import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Modal, Form, Input, Upload, message } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { fetchPartnersRequest, fetchPartnersSuccess, fetchPartnersFailure, addPartner, updatePartner, deletePartner } from '../Redux/actions/Partner';
import { getPartners, updatePartner as updatePartnerAPI, deletePartner as deletePartnerAPI, addPartner as addPartnerAPI } from '../API/Partner';

const PartnerTable = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 4 });
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editedPartner, setEditedPartner] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [form] = Form.useForm();

  const { partners } = useSelector((state) => state.Partners ? state.Partners : { partners: [] });

  useEffect(() => {
    dispatch(fetchPartnersRequest());
    getPartners()
      .then((partners) => {
        dispatch(fetchPartnersSuccess(partners));
      })
      .catch((error) => {
        dispatch(fetchPartnersFailure(error));
      });
  }, [dispatch]);

  const handleEdit = (record) => {
    setEditedPartner(record);
    setImageUrl(record.image);
    setEditModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setAddModalVisible(false);
    setEditModalVisible(false);
    setEditedPartner(null);
    setImageUrl('');
  };

  const handleAddPartner = async () => {
    try {
      const values = await form.validateFields();
      
      // Check if imageUrl exists
      if (!imageUrl) {
        message.error('Please upload an image before submitting');
        return;
      }
      
      const newPartnerData = { ...values, image: imageUrl };
      await addPartnerAPI(newPartnerData);
      dispatch(addPartner(newPartnerData));
      form.resetFields();
      setAddModalVisible(false);
      setImageUrl('');
      message.success('Partner added successfully');
    } catch (error) {
      console.error('Error adding partner:', error);
    }
  };
  
  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
  
      // Ensure imageUrl is available or fallback to the previous image
      const updatedPartnerData = { ...editedPartner, ...values, image: imageUrl || editedPartner.image };
      
      await updatePartnerAPI(editedPartner._id, updatedPartnerData);
      dispatch(updatePartner(editedPartner._id, updatedPartnerData));
      form.resetFields();
      setEditModalVisible(false);
      setImageUrl('');
      message.success('Partner updated successfully');
    } catch (error) {
      console.error('Error updating partner:', error);
      message.error('Error updating partner. Please try again.');
    }
  };
  

  const handleDelete = async (partnerId) => {
    try {
      await deletePartnerAPI(partnerId);
      dispatch(deletePartner(partnerId)); 
      message.success('Partner deleted successfully');
    } catch (error) {
      console.error('Error deleting partner:', error);
      message.error('Error deleting partner. Please try again.');
    }
  };

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      const url = info.file.response.secure_url;
      setImageUrl(url);
    }
  };

  const uploadProps = {
    action: 'https://api.cloudinary.com/v1_1/drukcn21i/upload',
    data: { upload_preset: 'duqax7wj' },
    onChange: handleChange,
  };
  const buttonStyle = {
    backgroundColor: '#0FB9B6',
    borderColor: '#0FB9B6',
    color: '#ffffff',
  };
  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      align: 'center',
      render: (image) => <img src={image} alt="Partner" style={{ display: 'block', margin: '0 auto', maxWidth: '100px' }} />,
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)} style={{ color: '#0FB9B6', fontSize: '1.5em' }}>
            <EditOutlined />
          </Button>
          <Button type="link" onClick={() => handleDelete(record._id)} style={{ color: '#0FB9B6', fontSize: '1.5em' }}>
            <DeleteOutlined />
          </Button>
        </span>
      ),
    },
  ];

  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
        <p style={{ color: '#0FB9B6', fontWeight: 'bold', marginRight: 'auto', marginLeft: '20px', fontSize: '1.2em' }}>Partners</p>
        <Button
          type="primary"
          onClick={() => setAddModalVisible(true)}
          icon={<PlusOutlined />}
          style={{ backgroundColor: '#0FB9B6', color: '#ffffff' }}
        >
          Add Partner
        </Button>
      </div>
      <Modal
        title="Add Partner"
        visible={addModalVisible}
        onOk={handleAddPartner}
        onCancel={handleCancel}
        okButtonProps={{ style: buttonStyle }}
        cancelButtonProps={{ style: buttonStyle }}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="link" label="Link" rules={[{ required: true, message: 'Please enter link' }]}>
            <Input style={{ borderColor: '#0FB9B6', color: '#0FB9B6' }} />
          </Form.Item>
          <Form.Item name="image" label="Image">
            <Upload {...uploadProps}>
              <Button style={{ backgroundColor: '#0FB9B6', borderColor: '#0FB9B6', color: '#ffffff' }}>Click to Upload</Button>
            </Upload>
            {imageUrl && (
              <div>
                <img src={imageUrl} alt="Partner" style={{ display: 'block', margin: '10px auto', maxWidth: '200px' }} />
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Partner"
        visible={editModalVisible}
        onOk={handleUpdate}
        onCancel={handleCancel}
        okButtonProps={{ style: buttonStyle }}
        cancelButtonProps={{ style: buttonStyle }}
      >
        <Form form={form} layout="vertical" initialValues={editedPartner}>
          <Form.Item name="link" label="Link" rules={[{ required: true, message: 'Please enter link' }]}>
            <Input style={{ borderColor: '#0FB9B6', color: '#0FB9B6' }} />
          </Form.Item>
          <Form.Item name="image" label="Image">
            <Upload {...uploadProps}>
              <Button style={{ backgroundColor: '#0FB9B6', borderColor: '#0FB9B6', color: '#ffffff' }}>Click to Upload</Button>
            </Upload>
            {editedPartner && editedPartner.image && (
              <div>
                <img src={editedPartner.image} alt="Partner" style={{ display: 'block', margin: '10px auto', maxWidth: '200px' }} />
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ overflowX: 'auto' }}>
        <Table
          columns={columns}
          dataSource={partners}
          pagination={{
            ...pagination,
            itemRender: (current, type, originalElement) => {
              if (type === 'prev' || type === 'next') {
                return <Button style={{ borderColor: '#d9a74a', color: 'white' }}>{originalElement}</Button>;
              }
              return originalElement;
            },
          }}
          onChange={setPagination}
        />
      </div>
    </div>
  );
};

export default PartnerTable;
