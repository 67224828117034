// src/App.js
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Layout, Menu } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProductOutlined,
  TeamOutlined,
  PieChartOutlined,
  LogoutOutlined,
} from '@ant-design/icons';

import Dashboard from './pages/Dashboard';
import Blog from './pages/Blog';
import User from './pages/User';
import Partner from './pages/Partner';
import Login from './pages/Login';
import { logout } from './Redux/actions/Login';
import PrivateRoute from './PrivateRoute'; // Correctly imported
import logo1 from './assets/logo 1.png';
import logo2 from './assets/logo 2.png';

const { Header, Sider, Content, Footer } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [logo, setLogo] = useState(logo1);
  const dispatch = useDispatch();

  const toggleLogo = () => {
    setLogo(logo === logo1 ? logo2 : logo1);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('authToken');
    window.location.href = "/login";
  };

  const buttonStyle = {
    backgroundColor: '#DAF7A6',
    borderColor: '#DAF7A6',
    color: 'black',
  };

  return (
    <BrowserRouter>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            background: '#DAF7A6',
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <div className="demo-logo-vertical">
            <img src={logo} alt="Logo" style={{ width: '100%', padding: '20px' }} />
          </div>
          <Menu
            mode="inline"
            style={{ background: '#DAF7A6' }}
            defaultSelectedKeys={['1']}
          >
            <Menu.Item
              key="1"
              icon={<PieChartOutlined />}
              style={{ color: 'black', background: '#0FB9B6', marginTop: '5px' }}
            >
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<TeamOutlined />}
              style={{ color: 'black', background: '#0FB9B6', marginTop: '5px' }}
            >
              <Link to="/users">Users</Link>
            </Menu.Item>
            <Menu.Item
              key="4"
              icon={<ProductOutlined />}
              style={{ color: 'black', background: '#0FB9B6', marginTop: '5px' }}
            >
              <Link to="/blogs">Blogs</Link>
            </Menu.Item>
            <Menu.Item
              key="4"
              icon={<ProductOutlined />}
              style={{ color: 'black', background: '#0FB9B6', marginTop: '5px' }}
            >
              <Link to="/partners">Partners</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200 }}>
          <Header
            style={{
              padding: 0,
              background: '#0FB9B6',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => {
                  setCollapsed(!collapsed);
                  toggleLogo();
                }}
                style={{
                  fontSize: '16px',
                  width: 40,
                  height: 40,
                  marginLeft: 12,
                  background: '#DAF7A6',
                  color: 'black',
                }}
              />
            </div>
            <div>
              <Button
                type="dashed"
                ghost
                style={{ ...buttonStyle, marginRight: '12px' }}
                icon={<LogoutOutlined />}
                onClick={handleLogout}
              >
                LogOut
              </Button>
            </div>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#EFEFEF',
              flex: '1 0 auto',
            }}
          >
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/users" element={<PrivateRoute element={User} />} />
              <Route path="/partners" element={<PrivateRoute element={Partner} />} />
              <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
              <Route path="/blogs" element={<PrivateRoute element={Blog} />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </Content>
          <Footer style={{ textAlign: 'center', background: '#0FB9B6', color: 'black', flexShrink: '0' }} className='font-bold'>
          </Footer>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
